import React from "react";
import BigNumber from "bignumber.js";
import { hashesToGH } from "../formatters";
import { hexToNumber } from "@etclabscore/eserialize";
import { Grid } from "@material-ui/core";
import ChartCard from "../ChartCard";
import { VictoryLine, VictoryBar, VictoryChart } from "victory";
import { useTranslation } from "react-i18next";

const config = {
  blockTime: 15, // seconds
  blockHistoryLength: 100,
  chartHeight: 200,
  chartWidth: 400,
};

const blockMapGasUsed = (block: any) => {
  return {
    x: hexToNumber(block.number),
    y: new BigNumber(block.gasUsed).dividedBy(1000000),
  };
};

const blockMapUncles = (block: any) => {
  return {
    x: hexToNumber(block.number),
    y: block.uncles.length,
  };
};

const blockMapHashRate = (block: any) => {
  return {
    x: hexToNumber(block.number),
    y: hashesToGH(
      new BigNumber(block.difficulty, 16).dividedBy(config.blockTime)
    ),
  };
};

const blockMapTransactionCount = (block: any) => {
  return {
    x: hexToNumber(block.number),
    y: block.transactions.length,
  };
};

interface IProps {
  blocks: any[];
  victoryTheme?: any;
}

const StatCharts: React.FC<IProps> = ({ blocks, victoryTheme }) => {
  const { t } = useTranslation();
  return (
    <Grid item container>
      <Grid key="hashChart" item xs={12} md={6} lg={3}>
        <ChartCard
          title={t("Hash Rate")}
          style={{
            background: "transparent",
            borderRadius: "8px",
            border: "1px solid #e7eaf3",
            // padding: "2rem",
            boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
            margin: "1rem",
          }}
        >
          <VictoryChart
            height={config.chartHeight}
            width={config.chartWidth}
            theme={victoryTheme as any}
          >
            <VictoryLine data={blocks.map(blockMapHashRate)} />
          </VictoryChart>
        </ChartCard>
      </Grid>
      <Grid key="txChart" item xs={12} md={6} lg={3}>
        <ChartCard
          title={t("Transaction Count")}
          style={{
            background: "transparent",
            borderRadius: "8px",
            border: "1px solid #e7eaf3",
            // padding: "2rem",
            boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
            margin: "1rem",
          }}
        >
          <VictoryChart
            height={config.chartHeight}
            width={config.chartWidth}
            theme={victoryTheme as any}
          >
            <VictoryBar data={blocks.map(blockMapTransactionCount)} />
          </VictoryChart>
        </ChartCard>
      </Grid>
      <Grid key="gasUsed" item xs={12} md={6} lg={3}>
        <ChartCard
          title={t("Gas Used")}
          style={{
            background: "transparent",
            borderRadius: "8px",
            border: "1px solid #e7eaf3",
            // padding: "2rem",
            boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
            margin: "1rem",
          }}
        >
          <VictoryChart
            height={config.chartHeight}
            width={config.chartWidth}
            theme={victoryTheme as any}
          >
            <VictoryBar data={blocks.map(blockMapGasUsed)} />
          </VictoryChart>
        </ChartCard>
      </Grid>
      <Grid key="uncles" item xs={12} md={6} lg={3}>
        <ChartCard
          title={t("Uncles")}
          style={{
            background: "transparent",
            borderRadius: "8px",
            border: "1px solid #e7eaf3",
            // padding: "2rem",
            boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
            margin: "1rem",
          }}
        >
          <VictoryChart
            height={config.chartHeight}
            width={config.chartWidth}
            theme={victoryTheme as any}
          >
            <VictoryBar data={blocks.map(blockMapUncles)} />
          </VictoryChart>
        </ChartCard>
      </Grid>
    </Grid>
  );
};

export default StatCharts;
